import { useState, useRef, useEffect, useReducer } from 'react';
import sub from 'date-fns/sub';
import { getOption } from '../utils';

const defaultState = {
  call: {},
  loading: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setcall': {
      const { call } = action.payload;
      return {
        ...state,
        call,
        loading: false,
      };
    }
    default:
      return state;
  }
};

const wsconnect = (text) => {
  const ws = new WebSocket('wss://www.mainpi.com/.ws_mainpi/');
  ws.onopen = () => {
    console.log('ws opened');
    ws.send(JSON.stringify({ type: 'query', text }));
  };
  ws.onclose = () => console.log('ws closed');
  ws.onerror = (e) => console.log('ws error', e);
  return ws;
};

export default function useMainpi(codes) {
  const requestString = codes.join(',');
  const [isPaused, setPause] = useState(false);
  const [state, dispatch] = useReducer(reducer, defaultState);
  const ws = useRef(null);
  const option = getOption();

  useEffect(() => {
    if (option !== 'mainpi') {
      return;
    }
    if (requestString === '') {
      return;
    }

    ws.current = wsconnect(requestString);
    return () => {
      ws.current.close();
    };
  }, [option, requestString]);

  useEffect(() => {
    if (!ws.current) return;

    ws.current.onmessage = (e) => {
      if (isPaused) return;
      const now = new Date();
      const callers = JSON.parse(e.data);
      const result = callers
        .map((c) => {
          if (c.call_nums === '') {
            return {
              id: c.id,
              num: '尚未開診',
              lastUpdate: undefined,
            };
          }
          const nums = JSON.parse(c.call_nums);
          return {
            id: c.id,
            num: `${nums[0]}`,
            lastUpdate: sub(now, { minutes: c.last_update }),
          };
        })
        .reduce((s, t) => {
          s[t.id] = t;
          return s;
        }, {});
      dispatch({ type: 'setcall', payload: { call: result } });
    };
  }, [requestString, isPaused]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (ws.current && requestString !== '') {
        if (ws.current.readyState === WebSocket.CLOSED) {
          setPause(true);
          ws.current = wsconnect(requestString);
        }
        if (ws.current.readyState === WebSocket.OPEN) {
          setPause(false);
          ws.current.send(
            JSON.stringify({ type: 'query', text: requestString })
          );
        }
      }
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [requestString, setPause]);

  if (option !== 'mainpi' || requestString === '') {
    return { mainpiCodes: state.call, loading: false };
  }

  return { mainpiCodes: state.call, loading: state.loading };
}
